<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true
  },
  priceId: {
    type: String,
    required: true
  },
  selectedPriceId: {
    type: String,
    required: true
  },
  discountedAmount: {
    type: Number,
    default: 0
  },
  discountText: {
    type: String,
    default: ''
  }
});

const cardColor = computed(() => {
  return props.priceId === props.selectedPriceId ? 'accent' : '';
});
</script>

<template>
  <v-card
    class="ma-1"
    :color="cardColor"
    min-width="300px"
    min-height="215px"
    hover
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text class="text-h6">
      <div v-if="discountedAmount > 0">
        $<span class="amount">{{ discountedAmount }}</span>/month
        <div><s>${{ amount }}/month</s></div>
        <div v-if="discountText">{{ discountText }}</div>
      </div>
      <div v-else>
        $<span class="amount">{{ amount }}</span>/month
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.amount {
  font-size: 3em;
  font-weight: bold;
}
</style>