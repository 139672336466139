<!-- eslint-disable vue/multi-word-component-names -->

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getAuth } from 'firebase/auth'
import { appBarStore } from '@/store/AppBarStore.js'

appBarStore.value.setTitle('Register');

const isLoading = ref(false)

const router = useRouter()
const store = useStore()
if (store.state.token === null) {
  router.push('/')
}

const handleStartAdventure = async () => {
  isLoading.value = true
  await pollForClaim()
    .then(() => {
      router.push('/adventure')
    })
    .catch((error) => {
      // TODO: route to a error page indicating checkout was succesful
      // user should log in again (log user out here???)
      console.error('Failed to set claim:', error)
    })
    .finally(() => {
      isLoading.value = false
    })
}

const pollForClaim = async (interval = 2000, maxAttempts = 10) => {
  const auth = getAuth()
  let attempts = 0
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      attempts++
      await auth.currentUser.getIdToken(true)
      const idTokenResult = await auth.currentUser.getIdTokenResult()
      const isRegistrationComplete = idTokenResult.claims.is_registration_complete || false
      store.dispatch('setIsRegistrationComplete', isRegistrationComplete)
      if (isRegistrationComplete) {
        clearInterval(intervalId)
        resolve(true)
      }
      if (attempts >= maxAttempts) {
        clearInterval(intervalId)
        reject(new Error('Claim not set within expected time'))
      }
    }, interval)
  })
}

</script>

<template>
  <v-card class="mx-auto mt-4 card-primary">
    <div class="ma-8 mt-8 text-left">
      <h1 class="my-1"> Woohoo! </h1>
      <h4 class="my-1"> Your account has been successfully registered </h4>
    </div>

    <div class="mx-8 my-10 text-left">
      <p class="my-6"> Thanks for supporting our crowdfunding campaign! Now it's time to play. Start an adventure to try out AdventuRAWR and be sure to share your feedback so we can improve the game. </p>
    </div>

    <div class="mt-12">
      <v-btn
        class="me-4"
        type="submit"
        color="primary"
        rounded="xl"
        size="large"
        flat
        :loading="isLoading"
        @click="handleStartAdventure"
      > Start a New Adventure </v-btn>
    </div>
  </v-card>
</template>
