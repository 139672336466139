<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { formatMonthString } from '@/utils/dateFormatter'
import { getPaymentMethodString, getPaymentMethodIcon } from '@/utils/paymentMethod'
import { useBillingPortal } from '@/composables/useBillingPortal'
import ProgressOverlay from '@/components/ProgressOverlay'

const store = useStore()

const isLoading = ref(false)
const isRedirecting = ref(false)
const subscription = computed(() => store.state.subscription.subscription)
const error = computed(() => store.state.subscription.error)

const SubscriptionStatus = Object.freeze({
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  ENDING: 'ending',
  UNDEFINED: 'undefined'
})

const subscriptionStatus = computed(() => {
  if (!subscription.value) return SubscriptionStatus.UNDEFINED
  switch (subscription.value.status) {
    case 'canceled':
      return SubscriptionStatus.CANCELLED
    case 'active':
      return subscription.value.cancelAtPeriodEnd ? SubscriptionStatus.ENDING : SubscriptionStatus.ACTIVE
    default:
      return SubscriptionStatus.UNDEFINED
  }
})

const isActiveOrEnding = computed(() => {
  return subscriptionStatus.value === SubscriptionStatus.ACTIVE || subscriptionStatus.value === SubscriptionStatus.ENDING
})

const isUndefinedOrCancelled = computed(() => {
  return subscriptionStatus.value === SubscriptionStatus.UNDEFINED || subscriptionStatus.value === SubscriptionStatus.CANCELLED
})

const nextDateString = computed(() => {
  let nextDateString = ""
  switch (subscriptionStatus.value) {
    case SubscriptionStatus.ACTIVE:
      nextDateString = `Next payment: ${formatMonthString(subscription.value.currentPeriodEndAt)}`
      break;
    case SubscriptionStatus.ENDING:
      nextDateString = `Ending: Your subscription will be canceled on ${formatMonthString(subscription.value.currentPeriodEndAt)}`
      break;
    case SubscriptionStatus.CANCELLED:
    case SubscriptionStatus.INACTIVE:
      nextDateString = `Canceled: Your subscription ended on ${formatMonthString(subscription.value.currentPeriodEndAt)}`
      break;
  }
  return nextDateString
})

const manageSubscriptionString = computed(() => {
  return subscriptionStatus.value === SubscriptionStatus.ENDING ? "Renew Plan" : "Manage Subscription"
})

const paymentMethodString = computed(() => {
  if (!subscription.value || !subscription.value.paymentMethod) return ''
  return getPaymentMethodString(subscription.value.paymentMethod)
})

const paymentMethodIcon = computed(() => {
  if (!subscription.value || !subscription.value.paymentMethod) return ''
  return getPaymentMethodIcon(subscription.value.paymentMethod)
})

const { createSession } = useBillingPortal();
const handleManageSubscription = async () => {
  console.log("creating session")
  isRedirecting.value = true
  const returnUrl = window.location.href;
  createSession(returnUrl)
    .then((url) => {
      console.log("url: " + url)
      window.location.href = url
    })
    // .catch((error) => errorMessage.value = error.displayMessage)
    .catch((error) => {
      isRedirecting.value = false
      console.error(error)
    })
}

const loadSubscription = async () => {
  isLoading.value = true
  store.dispatch('subscription/fetchSubscription')
    .catch((error) => console.error(error))
    .finally(() => isLoading.value = false)
}

onMounted(() => {
  loadSubscription()
})
</script>

<template>
  <v-card
    color="surface-light"
    class="mx-auto my-2 pa-6 text-body-1"
  >
    <ProgressOverlay
      :isLoading="isLoading"
    > 
      <div v-if="isActiveOrEnding">
        <p><span class="text-h6">Crowdfunding Backer:</span> ${{ subscription.amount / 100 }}/month (plus tax where applicable)</p>
        <p class="my-2">{{ nextDateString }}</p>
        <p v-if="subscription.paymentMethod" class="my-2">
          <v-icon class="mr-3">{{ paymentMethodIcon }}</v-icon>
          <span class="code-font">{{ paymentMethodString }}</span>
        </p>
        <v-divider class="my-4"></v-divider>
        <v-btn
          class="no-padding"
          variant="plain"
          :loading="isRedirecting"
          @click="handleManageSubscription"
        >{{ manageSubscriptionString }} ></v-btn>
      </div>
      <div v-else-if="isUndefinedOrCancelled">
        <p><span class="text-h6">Subscription Ended</span></p>
        <p class="my-2">You do not have an active subscription, renew it below.</p>
        <v-divider class="my-4"></v-divider>
        <v-btn
          class="no-padding"
          variant="plain"
          :to="{ name: 'UserSubscriptionRenew' }"
        >Restart Subscription ></v-btn>
      </div>
      <div v-else>
        <p class="text-error">{{ error }}</p>
      </div>
    </ProgressOverlay>
  </v-card>
</template>

<style scoped>
.no-padding {
  padding: 0;
}
.code-font {
  font-family: monospace;
  font-weight: 550;
  font-size: 0.875em;
}
</style>
