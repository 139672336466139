<script setup>
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  }
})
</script>

<template>
  <slot v-if="!props.isLoading"></slot>
  <div v-if="props.isLoading" class="overlay-container">
    <div class="blur-box"></div>
    <v-progress-circular
      indeterminate
      class="progress-indicator"
    ></v-progress-circular>
  </div>
</template>

<style>
.overlay-container {
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blur-box {
  filter: blur(5px);
  height: 100px;
  width: 100%;
  background-color: rgba(230, 230, 230, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.progress-indicator {
  z-index: 1;
}
</style>