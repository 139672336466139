<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed, watch, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { appBarStore } from '@/store/AppBarStore.js'
import ProgressSheet from '@/components/ProgressSheet.vue'
import RegisterPlan from '@/components/RegisterPlan.vue'
import useBillingSubscriptionCreate from '@/composables/useBillingSubscriptionCreate'
import RegisterCheckout from '@/components/RegisterCheckout.vue'

const isLoading = ref(false)
const isError = ref(false)
const priceId = ref('')
const clientSecret = ref('')
const isCheckoutTriggered = ref(false)
const isCheckoutSuccessful = ref(false)
const errorMessage = ref('')
const activeStep = ref("1")

const router = useRouter()

const previousStep = () => {
  let step = Number(activeStep.value)
  if (step > 1) {
    step--
  }
  activeStep.value = step.toString()
  errorMessage.value = ''
}

const stepperActionsDisabled = computed(() => {
  if (isLoading.value) {
    return true
  }

  let step = Number(activeStep.value)
  switch(step) {
    case 1: return 'prev'
    default: return false
  }
})
const checkoutLabel = 'Subscribe'
const nextButtonText = computed(() => {
  let step = Number(activeStep.value)
  switch(step) {
    // case 4: return checkoutLabel
    case 2: return checkoutLabel
    default: return 'Next'
  }
})
const nextStep = () => {
  errorMessage.value = ''
  let step = Number(activeStep.value)
  switch (step) {
    case 1:
      handleCreateSubscription()
      break
    case 2:
      handleCheckout()
      break
    default:
      step++
      activeStep.value = step.toString()
  }
}

const { createSubscription } = useBillingSubscriptionCreate()
const handleCreateSubscription = async () => {
  isLoading.value = true
  createSubscription(priceId.value)
    .then((data) => {
      clientSecret.value = data.clientSecret
      activeStep.value = "2"
    })
    .catch((error) => errorMessage.value = error.displayMessage)
    .finally(() => isLoading.value = false)
}

const handleCheckout = async () => {
  isLoading.value = true
  isCheckoutTriggered.value = true
}

const stopWatch = watch(isCheckoutTriggered, (newVal) => {
  if (!newVal) {
    isLoading.value = false
    if (isCheckoutSuccessful.value) {
      router.push({name: "UserAccount"})
    }
  }
})

onUnmounted(() => {
  stopWatch()
})

appBarStore.value.setTitle('Subscription')
</script>

<template>
  <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Restart Subscription</h1>

    <v-stepper v-model="activeStep">
      <v-stepper-header>
        <v-stepper-item
          title="Plan"
          value="1"
          color="tertiary" 
          :complete="activeStep > 1"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Checkout"
          value="2"
          color="tertiary" 
          :complete="activeStep > 2"
        ></v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <ProgressSheet :isLoading="isLoading">
            <RegisterPlan
              v-model:priceId="priceId"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="2">
          <ProgressSheet :isLoading="isLoading">
            <RegisterCheckout
              v-model:isCheckoutTriggered="isCheckoutTriggered"
              v-model:isCheckoutSuccessful="isCheckoutSuccessful"
              :priceId="priceId"
              :clientSecret="clientSecret"
              :checkoutLabel="checkoutLabel"
            />
          </ProgressSheet>
        </v-stepper-window-item>
      </v-stepper-window>

      <v-stepper-actions
        @click:prev="previousStep"
        @click:next="nextStep"
        :disabled=stepperActionsDisabled
        :next-text="nextButtonText"
      ></v-stepper-actions>

    </v-stepper>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>