<script setup>
import { onMounted, watch } from 'vue'
import PlanService from '@/utils/PlanService.js'

const props = defineProps({
  priceId: {
    type: String,
    required: true
  },
  clientSecret: {
    type: String,
    required: true
  },
  checkoutLabel: {
    type: String,
    default: 'Agree & Subscribe'
  }
});

const isCheckoutTriggered = defineModel('isCheckoutTriggered')
const isCheckoutSuccessful = defineModel('isCheckoutSuccessful')
isCheckoutSuccessful.value = false

const plan = PlanService.getPlanByPriceId(props.priceId)
const discountedAmount = plan.discountedAmount || plan.amount

const terms = `By clicking "${props.checkoutLabel}," you are enrolling in automatic payments of ${discountedAmount}/month (plus tax where applicable) that will continue until you cancel. You can cancel at any time, effective at the end of the billing period. There are no refunds or credits for partial months.`

const options = {
  clientSecret: props.clientSecret,
  // Fully customizable with appearance API.
  // appearance: {/*...*/},
};
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
const elements = stripe.elements(options)
const paymentElement = elements.create('payment')

function displayError(event) {
  const displayError = document.getElementById('payment-error-message')
  if (event.error) {
    displayError.textContent = event.error.message
  } else {
    displayError.textContent = ''
  }
}

async function handleCheckoutEvent() {
  const response = await stripe.confirmPayment({
    elements,
    confirmParams: {
      // return_url: "https://example.com/order/123/complete",
    },
    redirect: 'if_required'
  });

  if (response.error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    const messageContainer = document.querySelector('#payment-error-message');
    messageContainer.textContent = response.error.message;
    isCheckoutSuccessful.value = false;
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
    isCheckoutSuccessful.value = true;
  }
  isCheckoutTriggered.value = false;
}

onMounted(() => {
  paymentElement.mount('#payment-element')

  paymentElement.on('change', (event) => {
    displayError(event)
  })
})

watch(() => isCheckoutTriggered.value, (newVal) => {
  if (newVal) {
    handleCheckoutEvent()
  }
})
</script>

<template>
  <v-sheet class="mx-auto mt-4">
    <div class="mb-6 text-left">
      <span class="text-h6">Crowdfunding Backer:</span> ${{ discountedAmount }}/month (plus tax where applicable)<br>
      <span>No commitments, cancel anytime</span>
    </div>

    <!-- Payment elements inserted by Stripe SDK here -->
    <div id="payment-element"></div>
    <div id="payment-error-message" role="alert"></div>

    <div id="terms" class="text-left text-body-2 mt-4">
      {{ terms }}
    </div>
  </v-sheet>
</template>