const production = [
  {
    "title": "Crowdfunding Backer",
    "discountText": "50% off",
    "amount": 9.99,
    "discountedAmount": 4.99,
    "priceId": "price_1Q4OljEly1c33mwOEZ6HukHF",
    "isDefault": true
  },
];

const development = [
  {
    "title": "Crowdfunding Backer",
    "discountText": "50% off",
    "amount": 9.99,
    "discountedAmount": 4.99,
    "priceId": "price_1Q0VxzIyD8QVUrPIKlhZZyaC",
    "isDefault": true
  },
];

const plans = {
  production,
  development,
}

export default plans;