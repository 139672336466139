import { api } from '@/utils/api.js'

export function useBillingSubscriptionCheckout() {
  const getClientSecret = async () => {
    return api.billing.subscription.checkout()
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
        switch (error.response.status) {
          case 401:
            error.displayMessage = "Session has expired"
            break
          default:
            error.displayMessage = "Unable to create subscription"
        }
        throw error
      })
  }

  return {
    getClientSecret
  }
}