<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { appBarStore } from '@/store/AppBarStore.js'
import { useBillingSubscriptionCheckout } from '@/composables/useBillingSubscriptionCheckout'
import ProgressSheet from '@/components/ProgressSheet.vue'
import RegisterAccount from '@/components/RegisterAccount.vue'
import RegisterPlan from '@/components/RegisterPlan.vue'
import useBillingSubscriptionCreate from '@/composables/useBillingSubscriptionCreate'
import RegisterCheckout from '@/components/RegisterCheckout.vue'
import ProgressOverlay from '@/components/ProgressOverlay.vue'
import RegisterVerify from '@/components/RegisterVerify.vue'

const isResuming = ref(true)
const isLoading = ref(false);
const isError = ref(false);
const email = ref('')
const password = ref('')
const priceId = ref('')
const clientSecret = ref('')
const isCheckoutTriggered = ref(false)
const isCheckoutSuccessful = ref(false)
const errorMessage = ref('')

const auth = getAuth()
const store = useStore()
const router = useRouter() // get a reference to our vue router
const activeStep = ref("1")

appBarStore.value.update(
  'Register',
  [
    {
      label: 'Login',
      icon: 'mdi-login',
      routeName: 'Login'
    }
  ]
)

const resumeRegistrationStep = async () => {
  if (!store.state.user) return "1"
  if (!auth.currentUser.emailVerified) return "2"

  let resumeStep = "3"
  const { getClientSecret } = useBillingSubscriptionCheckout();
    await getClientSecret()
      .then((subscription) => {
        if (subscription) {
          priceId.value = subscription.priceId
          clientSecret.value = subscription.clientSecret
          resumeStep = "4"
        }
      })
      .catch((error) => console.error(error))
  return resumeStep
}

const resumeRegistration = async () => {
  const resumeStep = await resumeRegistrationStep()
  isResuming.value = false
  activeStep.value = resumeStep
}

const previousStep = () => {
  let step = Number(activeStep.value)
  if (step > 1) {
    step--
  }
  activeStep.value = step.toString()
  errorMessage.value = ''
}

const stepperActionsDisabled = computed(() => {
  if (isLoading.value || isResuming.value) {
    return true
  }

  let step = Number(activeStep.value)
  switch(step) {
    case 1: return 'prev'
    default: return false
  }
})
const checkoutLabel = 'Subscribe'
const nextButtonText = computed(() => {
  let step = Number(activeStep.value)
  switch(step) {
    case 4: return checkoutLabel
    default: return 'Next'
  }
})
const nextStep = () => {
  errorMessage.value = ''
  let step = Number(activeStep.value)
  switch (step) {
    case 1:
    handleCreateUser()
      break
    case 2:
      handleVerifyEmail()
      break;
    case 3:
      handleCreateSubscription()
      break
    case 4:
      handleCheckout()
      break
    default:
      step++
      activeStep.value = step.toString()
  }
}

const handleCreateUser = () => {
  isLoading.value = true;
  createUserWithEmailAndPassword(auth, email.value, password.value)
  .then((data) => {
    store.dispatch('setUser', data.user.uid)
    store.dispatch('setToken', data.user.accessToken)
    activeStep.value = "2"
  })
  .catch(error => {
    console.error(error)
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage.value = 'Email address already registered'
        break
      case 'auth/invalid-email':
        errorMessage.value = 'Invalid email'
        break
      case 'auth/operation-not-allowed':
        errorMessage.value = 'Email registration disabled'
        break  
      case 'auth/weak-password':
        errorMessage.value = 'Password not strong enough'
        break  
      case 'auth/missing-password':
        errorMessage.value = 'Password required'
        break  
      default:
        errorMessage.value = 'Unable to process registration'
        break
    }
    isError.value = true
  })
  .finally(() => {
    isLoading.value = false
  });
}

const handleVerifyEmail = async () => {
  isLoading.value = true

  if (!auth.currentUser.emailVerified) {
    await auth.currentUser.reload()
  }

  if (auth.currentUser.emailVerified) {
      activeStep.value = "3"
  } else {
    isError.value = true
    errorMessage.value = "Email verification required"
  }

  isLoading.value = false
}

const { createSubscription } = useBillingSubscriptionCreate()
const handleCreateSubscription = async () => {
  isLoading.value = true
  createSubscription(priceId.value)
    .then((data) => {
      clientSecret.value = data.clientSecret
      activeStep.value = "4"
    })
    .catch((error) => errorMessage.value = error.displayMessage)
    .finally(() => isLoading.value = false)
}

const handleCheckout = async () => {
  isLoading.value = true
  isCheckoutTriggered.value = true
  // TODO: trigger a "handleCheckout" function in the RegisterCheckout component
}

const stopWatch = watch(isCheckoutTriggered, (newVal) => {
  if (!newVal) {
    isLoading.value = false
    if (isCheckoutSuccessful.value) {
      router.push('/register/complete')
    }
  }
})

onMounted(() => {
  resumeRegistration()
})

onUnmounted(() => {
  stopWatch()
})
</script>

<template>
  <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Sign Up</h1>

    <ProgressOverlay
     :isLoading="isResuming"
     >
    <v-stepper v-model="activeStep">
      <v-stepper-header>
        <v-stepper-item
          title="Account"
          value="1"
          color="tertiary" 
          :complete="activeStep > 1"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Verify"
          value="2"
          color="tertiary" 
          :complete="activeStep > 2"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Plan"
          value="3"
          color="tertiary" 
          :complete="activeStep > 3"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Checkout"
          value="4"
          color="tertiary" 
          :complete="activeStep > 4"
        ></v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>

        <v-stepper-window-item value="1">
          <ProgressSheet :isLoading="isLoading">
            <RegisterAccount
                v-model:email="email"
                v-model:password="password"
                :errorMessage="errorMessage"
                :isDisabled="isLoading"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="2">
          <ProgressSheet :isLoading="isLoading">
            <RegisterVerify
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <ProgressSheet :isLoading="isLoading">
            <RegisterPlan
              v-model:priceId="priceId"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="4">
          <ProgressSheet :isLoading="isLoading">
            <RegisterCheckout
              v-model:isCheckoutTriggered="isCheckoutTriggered"
              v-model:isCheckoutSuccessful="isCheckoutSuccessful"
              :priceId="priceId"
              :clientSecret="clientSecret"
              :checkoutLabel="checkoutLabel"
            />
          </ProgressSheet>
        </v-stepper-window-item>

      </v-stepper-window>

      <v-stepper-actions
        @click:prev="previousStep"
        @click:next="nextStep"
        :disabled=stepperActionsDisabled
        :next-text="nextButtonText"
      ></v-stepper-actions>

    </v-stepper>
  </ProgressOverlay>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>