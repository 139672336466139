import { createStore } from 'vuex'
import subscription from './subscription'

const store = createStore({
  state: {
    user: null,
    token: null,
    adventureId: null,
    adventureName: null,
    invitationCodes: {},
    adventurePreviewData: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAdventureId(state, adventureId) {
      state.adventureId = adventureId;
    },
    setAdventureName(state, adventureName) {
      state.adventureName = adventureName;
    },
    setIsRegistrationComplete(state, isRegistrationComplete) {
      state.isRegistrationComplete = isRegistrationComplete;
    },
    setInvitationCode(state, { adventureId, code, expiresInMinutes, expiresAt }) {
      state.invitationCodes = {
        ...state.invitationCodes, 
        [adventureId]: { code, expiresInMinutes, expiresAt }
      };
    },
    setAdventurePreviewData(state, { invitationCode, createdAt, createdBy, displayNames, members, name, playerNames }) {
      state.adventurePreviewData[invitationCode] = { createdAt, createdBy, displayNames, members, name, playerNames };
    },
    deleteAdventurePreviewData(state, invitationCode) {
      delete state.adventurePreviewData[invitationCode]
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    setAdventureId({ commit }, adventureId) {
      commit('setAdventureId', adventureId);
    },
    setAdventureName({ commit }, adventureName) {
      commit('setAdventureName', adventureName);
    },
    setIsRegistrationComplete({ commit }, isRegistrationComplete) {
      commit('setIsRegistrationComplete', isRegistrationComplete);
    },
    setInvitationCode({ commit }, payload) {
      commit('setInvitationCode', payload);
    },
  },
  modules: {
    subscription,
  },
})

export default store
