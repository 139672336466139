<script setup>
import RegisterPlanCard from '@/components/RegisterPlanCard.vue'
import PlanService from '@/utils/PlanService.js'

const selectedPriceId = defineModel('priceId')
const plans = PlanService.getPlans()
const defaultPlan = PlanService.getDefaultPlan()
selectedPriceId.value = defaultPlan.priceId;

const setSelectedPrice = (priceId) => {
  selectedPriceId.value = priceId;
};
</script>

<template>
  <div class="mt-3 mb-6">
    Support AdventuRAWR and play now with our Crowdfunding subscription
  </div>
  <v-sheet class="mx-auto mt-4">
    <v-row justify="center">
      <v-col
        v-for="plan in plans"
        :key="plan.priceId"
        cols="3"
        md="5"
        sm="12"
      >
        <RegisterPlanCard
          :title="plan.title"
          :discountText="plan.discountText"
          :amount="plan.amount"
          :discountedAmount="plan.discountedAmount"
          :priceId="plan.priceId"
          :selectedPriceId="selectedPriceId"
          @click="() => setSelectedPrice(plan.priceId)"
        />
      </v-col>
    </v-row>
  </v-sheet>
  <div class="mt-3 mb-6">
    No commitments, cancel anytime 
  </div>
  <div class="mt-3 mb-6">
    TODO: Add crowdfunding disclaimers here
  </div>
</template>