import axios from 'axios'

export const configureApi = (token) => {
  axios.defaults.baseURL = process.env.VUE_APP_ADVENTURAWR_API_URL
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

const adventure = {

  create: async (characterName) => {
    return axios.post('/adventure/create', {
      characterName: characterName
    })
  },

  invite: async (adventureId) => {
    return axios.post('/adventure/invite', { 
      adventureId: adventureId
    })
  },

  preview: async (invitationCode) => {
    return axios.post('/adventure/preview', { 
      invitationCode: invitationCode
    })
  },

  join: async (invitationCode, characterName) => {
    return axios.post('/adventure/join', { 
      invitationCode: invitationCode,
      characterName: characterName
    })
  },
  
  resume: async (adventureId) => {
    return axios.post('/adventure/resume', { 
      adventureId: adventureId
    })
  },

  prompt: async (adventureId, message) => {
    return axios.post('/adventure/prompt', { 
      adventureId: adventureId,
      input: message
    })
  },

  feedback: async (feedbackId, score) => {
    return axios.post('/adventure/feedback', { 
      feedbackId: feedbackId,
      score: score
    })
  }
}

const profile = {

  update: async (displayName) => {
    return axios.post('/profile/update', {
      displayName: displayName
    })
  },
}

const billing = {
  customer: {
    create: async (email) => {
      return axios.post('/billing/customer', {
        email: email
      })
    },
  },

  subscription: {
    create: async (priceId) => {
      return axios.post('/billing/subscription', {
        priceId: priceId
      })
    },
    get: async () => {
      return axios.get('/billing/subscription', {
      })
    },
    cancel: async () => {
      return axios.delete('/billing/subscription', {
      })
    },
    checkout: async () => {
      return axios.get('/billing/subscription/checkout', {
      })
    },
  },

  setupIntent: {
    create: async () => {
      return axios.post('/billing/setupintent', {
      })
    },
  },

  portal: {
    session: {
      create: async (returnUrl) => {
        return axios.post('/billing/portal/session', {
          returnUrl: returnUrl
        })
      },
    }
  }
}

export const api = {
  adventure: adventure,
  profile: profile,
  billing: billing,
}

export default api
